import "../../HireStyle.css";
import Card from "../Card";
import { FaPersonBreastfeeding } from "react-icons/fa6";
import { MdLaptop } from "react-icons/md";
import { MdOutlineMobileFriendly } from "react-icons/md";
import { AiOutlineFontColors } from "react-icons/ai";
import { HiMiniRectangleGroup } from "react-icons/hi2";
import { GiAbstract013 } from "react-icons/gi";
import Select, { CSSObjectWithLabel } from "react-select";
import { GrFormNextLink } from "react-icons/gr";
import { useHireContext } from "../../HireContext";
import CardForMulti from "../CardForMulti";
import DashedCard from "../DashedCard";
import { useEffect, useState } from "react";
const skills = [
  "JavaScript",
  "Python",
  "Java",
  "C++",
  "Ruby",
  "PHP",
  "HTML",
  "CSS",
  "React",
  "Angular",
  "Vue.js",
  "Node.js",
  "Express.js",
  "Django",
  "Laravel",
  "ASP.NET",
  "iOS Development",
  "Android Development",
  "React Native",
  "Flutter",
  "Xamarin",
  "Kotlin",
  "SwiftUI",
  "Java (Android)",
  "Objective-C",
  "SQL",
  "MySQL",
  "PostgreSQL",
  "MongoDB",
  "Oracle",
  "SQLite",
  "Firebase",
  "Redis",
  "Cassandra",
  "Elasticsearch",
  "Amazon Web Services (AWS)",
  "Microsoft Azure",
  "Google Cloud Platform (GCP)",
  "Docker",
  "Kubernetes",
  "Serverless Architecture",
  "DevOps",
  "Terraform",
  "Ansible",
  "Jenkins",
  "Data Analysis",
  "Machine Learning",
  "Deep Learning",
  "Natural Language Processing (NLP)",
  "TensorFlow",
  "PyTorch",
  "Scikit-learn",
  "Pandas",
  "NumPy",
  "R Programming",
  "Test Automation",
  "Selenium",
  "JUnit",
  "TestNG",
  "Cucumber",
  "Load Testing",
  "Performance Testing",
  "Security Testing",
  "Continuous Integration/Continuous Delivery (CI/CD)",
  "Agile Testing",
  "User Interface (UI) Design",
  "User Experience (UX) Design",
  "Wireframing",
  "Prototyping",
  "Adobe XD",
  "Sketch",
  "Figma",
  "InVision",
  "Usability Testing",
  "Interaction Design",
  "Graphic Design",
  "Illustration",
  "Motion Graphics",
  "Video Editing",
  "Photography",
  "Copywriting",
  "Content Marketing",
  "Search Engine Optimization (SEO)",
  "Social Media Marketing",
  "Pay-Per-Click (PPC) Advertising",
  "Email Marketing",
  "Google Analytics",
  "Project Management",
  "Agile Methodology",
  "Scrum",
  "Kanban",
  "Lean",
  "Business Analysis",
  "Financial Analysis",
  "Market Research",
  "Salesforce",
  "Customer Relationship Management (CRM)",
  "Leadership",
  "Teamwork",
  "Communication Skills",
  "Problem Solving",
  "Critical Thinking",
  "Decision Making",
  "Time Management",
  "Organizational Skills",
  "Negotiation",
  "Presentation Skills",
  "Public Speaking",
  "Emotional Intelligence",
  "Creativity",
  "Innovation",
  "Strategic Thinking",
  "Data Visualization",
  "UI/UX Design",
  "Front-end Development",
  "Back-end Development",
  "Full-stack Development",
  "Software Engineering",
  "Quality Assurance",
  "Cybersecurity",
  "Network Administration",
  "Cloud Computing",
  "Virtual Reality (VR)",
  "Augmented Reality (AR)",
  "Blockchain",
  "Cryptocurrency",
  "Ethical Hacking",
  "Artificial Intelligence (AI)",
  "Robotics",
  "Internet of Things (IoT)",
  "Big Data",
  "Data Science",
  "Quantum Computing",
  "Bioinformatics",
  "Genetic Engineering",
  "Renewable Energy",
  "Green Technology",
  "Sustainability",
  "Space Exploration",
  "Astrophysics",
  "Biotechnology",
  "Nanotechnology",
  "Medical Research",
  "Pharmaceuticals",
  "Virtual Assistant",
  "Translation",
  "Transcription",
  "Proofreading",
  "Editing",
  "Content Writing",
  "Blogging",
  "E-commerce",
  "Digital Marketing",
  "Web Design",
  "Mobile App Design",
  "Game Development",
  "Virtual Reality (VR) Development",
  "Augmented Reality (AR) Development",
  "Data Engineering",
  "Data Warehousing",
  "Data Mining",
  "Cloud Architecture",
  "Network Security",
  "Ethical Hacking",
  "Quantum Computing",
  "Bioinformatics",
  "Genetic Engineering",
  "Renewable Energy",
  "Green Technology",
  "Sustainability",
  "Space Exploration",
  "Astrophysics",
  "Biotechnology",
  "Nanotechnology",
  "Medical Research",
  "Pharmaceuticals",
  "Virtual Assistant",
  "Translation",
  "Transcription",
  "Proofreading",
  "Editing",
  "Content Writing",
  "Blogging",
  "E-commerce",
  "Digital Marketing",
  "Web Design",
  "Mobile App Design",
  "Game Development",
  "Virtual Reality (VR) Development",
  "Augmented Reality (AR) Development",
  "Data Engineering",
  "Data Warehousing",
  "Data Mining",
  "Cloud Architecture",
  "Network Security",
];

const skillsArray = skills.map((skill) => {
  return { label: skill, value: skill, isSelected: false };
});
interface selectOptionsType {
  value: string;
  label: string;
  isSelected: boolean;
}
export default function Step1() {
  const cardArray = [
    {
      title: "React",
      src: "React.svg",
    },
    {
      title: "Typescript",
      src: "Typescript.svg",
    },
    {
      title: "Python",
      src: "Python.svg",
    },
    {
      title: "Node.js",
      src: "Node.js.svg",
    },
    {
      title: "AWS",
      src: "AWS.svg",
    },
    {
      title: "JavaScript",
      src: "Javascript.svg",
    },
    {
      title: "React Native",
      src: "React.svg",
    },
    {
      title: "Next.js",
      src: "nextjsTest.svg",
    },
  ];
  const selectOptionsInit = skillsArray;
  const [selectOptions, setSelectOptions] = useState<selectOptionsType[]>([
    ...selectOptionsInit,
  ]);
  const [skills, setSkills] = useState<selectOptionsType[]>([]);
  const { changeStepNumber, stepNumber, transition, setFormData, formData } =
    useHireContext();
  useEffect(() => {
    if (skills) setFormData({ ...formData!, skills: skills });
  }, [skills]);
  useEffect(() => {
    setSkills(formData!.skills || []);
  }, []);
  return (
    <div style={{ transition: "all 0.4s" }}>
      <div className="cards-Answer-container">
        <div className="skills-container">
          {cardArray.map((item) => (
            <div className="card-Answer-container" style={{ width: "100%" }}>
              <CardForMulti
                isSelected={
                  skills.findIndex((e) => e.label === item.title) !== -1
                }
                title={item.title}
                src={`/images/hire-skills/${item.src}`}
                onClick={() => {
                  let selectedIndex = skills.findIndex(
                    (e) => e.label === item.title
                  );
                  let tempArray = [...skills];
                  if (selectedIndex === -1) {
                    tempArray.push({
                      isSelected: true,
                      value: item.title,
                      label: item.title,
                    });
                  } else {
                    tempArray.splice(selectedIndex, 1);
                  }
                  setSkills(tempArray);
                }}
              />
            </div>
          ))}
        </div>
        <div style={{ width: "98%" }}>
          <div
            style={{
              marginBlock: "0.5rem",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <hr
              style={{
                borderTop: "3px solid black",
                opacity: "1",
                width: "45%",
              }}
            />
            <h4
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingInline: "1rem",
                width: "10%",
                margin: "0px",
                color: "black",
              }}
            >
              Or
            </h4>
            <hr
              style={{
                borderTop: "3px solid black",
                opacity: "1",
                width: "45%",
              }}
            />
          </div>
          <Select
            placeholder={"Any extra skill?"}
            isClearable
            isSearchable
            key={skills.length}
            onChange={(element) => {
              let tempArray = [...skills];
              let tempSelectOptions = [...selectOptions];
              const selectedIndex = tempSelectOptions.findIndex(
                (item) => item.value === element?.value
              );
              if (selectedIndex !== -1) {
                tempSelectOptions[selectedIndex].isSelected = true;
              }
              if (element) tempArray.push(element);
              console.table(tempArray);
              setSkills(tempArray);
              setSelectOptions(tempSelectOptions);
            }}
            options={selectOptions.filter(
              (option) => option.isSelected === false
            )}
            menuPlacement={"auto"}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: "black",
                border: state.isFocused
                  ? "1px solid #916bbb"
                  : "1px solid black",
                outline: "none",
              }),
              singleValue: (base) => ({
                ...base,
                color: "white",
              }),
              menu: (base) => ({
                ...base,
              }),
              menuList: (base) => ({
                ...base,
              }),
            }}
          />

          <div
            style={{
              maxWidth: "100%",
              maxHeight: "70px",
              overflow: "auto",
              marginBlockStart: "1rem",
              display: "flex",
              alignItems: "center",
              gap: 4,
              flexWrap: "wrap",
            }}
          >
            {skills.map((item, index) => (
              <div key={item.label}>
                <DashedCard
                  title={item.label}
                  onClose={() => {
                    let tempArray = [...skills];
                    let tempSelectOptions = [...selectOptions];
                    const selectedIndex = tempSelectOptions.findIndex(
                      (object) => object.value === item.value
                    );
                    const cardIndex = cardArray.findIndex(
                      (e) => e.title === item.label
                    );
                    if (cardIndex !== -1) {
                      console.log(cardIndex);
                      tempArray.splice(index, 1);
                    } else {
                      tempSelectOptions[selectedIndex].isSelected = false;
                      tempArray.splice(index, 1);
                    }
                    setSelectOptions(tempSelectOptions);
                    setSkills(tempArray);
                  }}
                />
              </div>
            ))}
          </div>

          <button
            className={`hire-getstarted-btn ${
              formData!.skills.length < 1 ? "disable-hire-btn" : ""
            }`}
            style={{
              marginBlockStart: "1.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#5d17eb",
              letterSpacing: "1.5px",
              color: "white",
              borderRadius: "8px",
              outline: "none",
              border: "3px solid transparent",
              padding: "0.5rem 1rem",
              fontWeight: "600",
              transition: "all 0.5s",
              gap: 8,
            }}
            disabled={formData!.skills.length < 1}
            onClick={() => {
              transition();
            }}
          >
            Next <GrFormNextLink style={{ fontSize: "20px" }} />
          </button>
        </div>
      </div>
    </div>
  );
}
