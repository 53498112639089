import "../../HireStyle.css";
import Card from "../Card";
import { FaPersonBreastfeeding } from "react-icons/fa6";
import { MdLaptop } from "react-icons/md";
import { MdOutlineMobileFriendly } from "react-icons/md";
import { AiOutlineFontColors } from "react-icons/ai";
import { HiMiniRectangleGroup } from "react-icons/hi2";
import { GiAbstract013 } from "react-icons/gi";
import Select from "react-select";
import { GrFormNextLink } from "react-icons/gr";
import { useHireContext } from "../../HireContext";
import { useEffect, useState } from "react";

export default function Step1() {
  const cardArray = [
    {
      title: "Full-stack Web Developer",
      icon: <FaPersonBreastfeeding style={{ fontSize: "24px" }} />,
    },
    {
      title: "Front-end Web Developer",
      icon: <HiMiniRectangleGroup style={{ fontSize: "24px" }} />,
    },
    {
      title: "Back-end Web Developer",
      icon: <GiAbstract013 style={{ fontSize: "24px" }} />,
    },
    {
      title: "Mobile Developer",
      icon: <MdOutlineMobileFriendly style={{ fontSize: "24px" }} />,
    },
    {
      title: "Machine Learning Engineer",
      icon: <MdLaptop style={{ fontSize: "24px" }} />,
    },
    {
      title: "UI/UX Designer",
      icon: <AiOutlineFontColors style={{ fontSize: "24px" }} />,
    },
  ];
  const selectOptions = [
    { label: "AI Engineer", value: "AI Engineer" },
    { label: "Architect", value: "Architect" },
    { label: "Automation QA Engineer", value: "Automation QA Engineer" },
    { label: "Blockchain Engineer", value: "Blockchain Engineer" },
    { label: "Cloud Engineer", value: "Cloud Engineer" },
    { label: "Data Analyst", value: "Data Analyst" },
    { label: "Data Engineer", value: "Data Engineer" },
    { label: "Data Scientist", value: "Data Scientist" },
    { label: "Database Administrator", value: "Database Administrator" },
    { label: "DevOps", value: "DevOps" },
    {
      label: "Embedded Software Engineer",
      value: "Embedded Software Engineer",
    },
    { label: "Game Developer", value: "Game Developer" },
    { label: "Manual QA Engineer", value: "Manual QA Engineer" },
    { label: "Product Designer", value: "Product Designer" },
    { label: "Site Reliability Engineer", value: "Site Reliability Engineer" },
    { label: "Team lead", value: "Team lead" },
    { label: "Tech lead", value: "Tech lead" },
  ];
  const { changeStepNumber, stepNumber, transition, setFormData, formData } =
    useHireContext();
  const [keyToResetRadioSelection, setKeyToResetRadioSelection] = useState(2);
  const [keyToResetSelectSelection, setKeyToResetSelectSelection] = useState(3);

  return (
    <div>
      <div
        className="cards-Answer-container"
        key={`${keyToResetRadioSelection}`}
      >
        {cardArray.map((item, index) => (
          <div key={item.title} id={`specializaion-${index}`}>
            <Card
              isChecked={item.title === formData?.specializaion}
              title={item.title}
              icon={item.icon}
              isLink={item.title === "I have an idea of what I want"}
              onClick={() => {
                setFormData({
                  ...formData!,
                  specializaion: item?.title || "",
                });
                setKeyToResetSelectSelection((val) => val + 1);
              }}
            />
          </div>
        ))}
        <a
          href="https://meetings-eu1.hubspot.com/prolevant"
          style={{ width: "99%" }}
        >
          <button
            className=""
            style={{
              backgroundColor: "black",
              height: "2.5rem",
              color: "white",
              width: "100%",
              borderRadius: "0.5rem",
              border: "none",
              outline: "none",
            }}
          >
            I have an idea of what I want
          </button>
        </a>
      </div>

      <div
        style={{
          marginBlock: "0.5rem",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <hr
          style={{
            borderTop: "3px solid black",
            opacity: "1",
            width: "45%",
          }}
        />
        <h4
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingInline: "1rem",
            width: "10%",
            margin: "0px",
            color: "black",
          }}
        >
          Or
        </h4>
        <hr
          style={{
            borderTop: "3px solid black",
            opacity: "1",
            width: "45%",
          }}
        />
      </div>
      <Select
        key={keyToResetSelectSelection}
        isClearable
        isSearchable
        options={selectOptions}
        placeholder={"Any specializaIon?"}
        onChange={(e) => {
          setKeyToResetRadioSelection((val) => val + 1);
          setFormData({ ...formData!, specializaion: e?.label || "" });
        }}
        menuPlacement={"auto"}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: "black",
            border: state.isFocused ? "1px solid #916bbb" : "1px solid black",
            outline: "none",
          }),
          singleValue: (base) => ({
            ...base,
            color: "white",
          }),
          menu: (base) => ({
            ...base,
          }),
          menuList: (base) => ({
            ...base,
          }),
        }}
      />
      <button
        className={`hire-getstarted-btn ${
          formData!.specializaion === "" ? "disable-hire-btn" : ""
        }`}
        style={{
          marginBlockStart: "1.5rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#5d17eb",
          color: "white",
          borderRadius: "8px",
          outline: "none",
          border: "3px solid transparent",
          padding: "0.5rem 1rem",
          fontWeight: "600",
          letterSpacing: "1.5px",
          transition: "all 0.5s",
          gap: 8,
        }}
        disabled={formData!.specializaion === ""}
        onClick={() => {
          transition();
        }}
      >
        Get Started <GrFormNextLink style={{ fontSize: "20px" }} />
      </button>
    </div>
  );
}
