import { useNavigate } from "react-router-dom";

export default function WorkContentSection() {
  const navigate = useNavigate();

  return (
    <section id="content">
      <div className="content-wrap py-0">
        {/* <!-- Page Sub Menu
                ============================================= --> */}

        <div className="section center mt-0 pt-0 clearfix">
          <div className="container-fluid px-5 clearfix">
            <div
              className="row col py-5 justify-content-center"
              style={{
                backgroundColor: " rgba(0, 0, 0, 0.08)",
                borderRadius: "24px",
              }}
            >
              <div className="col-md-10">
                <div className="heading-block center border-bottom-0 bottommargin-sm clearfix">
                  <h2 className="nott col-12" style={{ color: "#0A0A0A" }}>
                    Your affordable & flexible development team
                  </h2>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <h2 className="service-title">Subscribe</h2>
                    <p className="service-desc fs-6">
                      Subscribe to a plan according to your development need
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h2 className="service-title">
                      Work with top global talent
                    </h2>
                    <p className="service-desc fs-6">
                      We will build a team of developers, designers based on
                      your requests
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h2 className="service-title">No Contracts</h2>
                    <p className="service-desc fs-6">
                      Stop whenever you want. No contracts or commitments!
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a
                  href="/#/hire"
                  // onClick={() => {
                  //   navigate("/pricing");
                  // }}
                  className="button button-circle button-dark"
                >
                  Get started
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section mt-0 pt-0 clearfix"
          style={{ padding: "70px 0" }}
        >
          <div className="px-5 container-fluid clearfix">
            <div className="row">
              <div
                className="promo promo-light p-4 p-md-5 mb-5 text-center text-md-start"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                  borderRadius: "24px",
                }}
              >
                <div className="row align-items-center">
                  <div className="col-12 col-lg">
                    <h2 className="pb-0 mb-0" style={{ color: "black" }}>
                      Want to talk with us first?
                    </h2>
                    <p className="lead mb-0">
                      Learn more about how we work and how we can help you
                      during a free call.
                    </p>
                  </div>
                  <div className="col-12 col-lg-auto mt-4 mt-lg-0">
                    <a
                      href="https://meetings-eu1.hubspot.com/prolevant"
                      target="_blank"
                      className="button button-circle button-dark"
                    >
                      Book a call
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
