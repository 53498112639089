import { useNavigate } from "react-router-dom";

export default function WorkCompanySection() {
  const navigate = useNavigate();
  return (
    <section id="companies" className="section mt-0 pt-0">
      <div className="container-fluid px-3 px-md-5">
        <div className="row text-center">
          <div className="col-md-6">
            <div className="prolevant-card portfolio-item p-2">
              <div
                className="portfolio-image shadow-lg rounded"
                style={{ borderRadius: "10px !important" }}
              >
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src="images/projects/01.png" alt="Live Preview" />
                </a>
              </div>
              <div className="portfolio-desc pb-0">
                <h3>
                  <a
                    className="d-flex"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Hyundai&nbsp;|&nbsp;<span>Corporate business website</span>
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="prolevant-card portfolio-item p-2">
              <div
                className="portfolio-image shadow-lg rounded"
                style={{ borderRadius: "10px !important" }}
              >
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src="images/projects/02.png" alt="Live Preview" />
                </a>
              </div>
              <div className="portfolio-desc pb-0">
                <h3>
                  <a
                    className="d-flex"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    MenaLabs&nbsp;|&nbsp;<span>Medical lab software</span>
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="prolevant-card portfolio-item p-2">
              <div
                className="portfolio-image shadow-lg rounded"
                style={{ borderRadius: "10px !important" }}
              >
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src="images/projects/03.png" alt="Live Preview" />
                </a>
              </div>
              <div className="portfolio-desc pb-0">
                <h3>
                  <a
                    className="d-flex"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Nova Track&nbsp;|&nbsp;
                    <span>Tracking system for logistic companies</span>
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="prolevant-card portfolio-item p-2">
              <div
                className="portfolio-image shadow-lg rounded"
                style={{ borderRadius: "10px !important" }}
              >
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src="images/projects/04.png" alt="Live Preview" />
                </a>
              </div>
              <div className="portfolio-desc pb-0">
                <h3>
                  <a
                    className="d-flex"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    BusyHive&nbsp;|&nbsp;
                    <span>Location based task marketplace</span>
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="prolevant-card portfolio-item p-2">
              <div
                className="portfolio-image shadow-lg rounded"
                style={{ borderRadius: "10px !important" }}
              >
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src="images/projects/05.png" alt="Live Preview" />
                </a>
              </div>
              <div className="portfolio-desc pb-0">
                <h3>
                  <a
                    className="d-flex"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Medpocket&nbsp;|&nbsp;
                    <span>Virtual pharmacy app and cms</span>
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="prolevant-card portfolio-item p-2">
              <div
                className="portfolio-image shadow-lg rounded"
                style={{ borderRadius: "10px !important" }}
              >
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src="images/projects/06.png" alt="Live Preview" />
                </a>
              </div>
              <div className="portfolio-desc pb-0">
                <h3>
                  <a
                    className="d-flex"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    BIBO&nbsp;|&nbsp;
                    <span>E-commerce solution for kids clothing brand</span>
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="prolevant-card portfolio-item p-2">
              <div
                className="portfolio-image shadow-lg rounded"
                style={{ borderRadius: "10px !important" }}
              >
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src="images/projects/07.png" alt="Live Preview" />
                </a>
              </div>
              <div className="portfolio-desc pb-0">
                <h3>
                  <a
                    className="d-flex"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Techouse&nbsp;|&nbsp;
                    <span>Online shopping for consumer electronics</span>
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="prolevant-card portfolio-item p-2">
              <div
                className="portfolio-image shadow-lg rounded"
                style={{ borderRadius: "10px !important" }}
              >
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src="images/projects/08.png" alt="Live Preview" />
                </a>
              </div>
              <div className="portfolio-desc pb-0">
                <h3>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="d-flex"
                  >
                    Daa'em&nbsp;|&nbsp;
                    <span>Corporate website for real estate group</span>
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="prolevant-card portfolio-item p-2">
              <div
                className="portfolio-image shadow-lg rounded"
                style={{ borderRadius: "10px !important" }}
              >
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src="images/projects/09.png" alt="Live Preview" />
                </a>
              </div>
              <div className="portfolio-desc pb-0">
                <h3>
                  <a
                    className="d-flex"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    SportsLife&nbsp;|&nbsp;
                    <span>Mobil e-commerce app for a sports retailer</span>
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="prolevant-card portfolio-item p-2">
              <div
                className="portfolio-image shadow-lg rounded"
                style={{ borderRadius: "10px !important" }}
              >
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src="images/projects/10.png" alt="Live Preview" />
                </a>
              </div>
              <div className="portfolio-desc pb-0">
                <h3>
                  <a
                    className="d-flex"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Afamia&nbsp;|&nbsp;
                    <span>Custom hotel entertainment system</span>
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
