import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { ReactNode } from "react";

export const Carousel = ({
  children,
  slidesPerView = 3,
  spaceBetween = 50,
  isLoop = true,
}: {
  children: ReactNode;
  slidesPerView?: number;
  spaceBetween?: number;
  isLoop?: boolean;
}) => {
  return (
    <Swiper
      grabCursor={true}
      loop={isLoop}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      breakpoints={{
        "0": {
          slidesPerView: 1,
          spaceBetween: 20,
        },

        "767": {
          slidesPerView: 2.5,
          spaceBetween: 20,
        },
        "1250": {
          slidesPerView: 1.8,
          spaceBetween: 20,
        },
        "1350": {
          slidesPerView: 1.8,
          spaceBetween: 20,
        },
        "1450": {
          slidesPerView: 1.8,
          spaceBetween: 20,
        },
      }}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Pagination, Navigation]}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {children}
    </Swiper>
  );
};
