import { useNavigate } from "react-router-dom";

export default function CompaniesSliders() {
  const navigate = useNavigate();

  return (
    <section id="companies" className="section mt-0 pt-0">
      <div className="container-fluid px-2">
        <div className="row text-center">
          <div className="col-md-4">
            <div className="prolevant-card portfolio-item p-2">
              <div
                className="portfolio-image shadow-lg rounded"
                style={{ borderRadius: "10px !important" }}
              >
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                  target="_blank"
                >
                  <img src="/images/projects-home/01.png" alt="Live Preview" />
                </a>
              </div>
              <div className="portfolio-desc pb-0">
                <h3>
                  <a
                    className="d-flex"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    MenaLabs&nbsp;|&nbsp;<span>Medical lab software</span>
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="prolevant-card portfolio-item p-2">
              <div
                className="portfolio-image shadow-lg rounded"
                style={{ borderRadius: "10px !important" }}
              >
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src="/images/projects-home/02.png" alt="Live Preview" />
                </a>
              </div>
              <div className="portfolio-desc pb-0">
                <h3>
                  <a
                    className="d-flex"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Hyundai&nbsp;|&nbsp;<span>Corporate business website</span>
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="prolevant-card portfolio-item p-2">
              <div
                className="portfolio-image shadow-lg rounded"
                style={{ borderRadius: "10px !important" }}
              >
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src="/images/projects-home/03.png" alt="Live Preview" />
                </a>
              </div>
              <div className="portfolio-desc pb-0">
                <h3>
                  <a
                    className="d-flex"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    BusyHive&nbsp;|&nbsp;
                    <span>Location based task marketplace</span>
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
