export default function privacy() {
  return (
    <section id="content">
      <div className="content-wrap py-0">
        {/* <!-- Page Sub Menu
                ============================================= --> */}

        <div className="section mt-0 pt-0 clearfix">
          <div className="container-fluid px-5 clearfix">
            <div className="row col mt-5 mt-md-0">
              <div className="col-md-8 offset-md-2">
                <div className="heading-block border-bottom-0 bottommargin-sm clearfix">
                  <h2 className="nott col-12" style={{ color: "#0A0A0A" }}>
                    Privacy policy
                  </h2>
                </div>
              </div>
              <div className="col-md-8 offset-md-2">
                <p className="lead mb-5">
                  This page informs you of our policies regarding the
                  collection, use and disclosure of Personal Information we
                  receive from users of the site. We use your Personal
                  Information only for providing and improving the site. By
                  using the site, you agree to the collection and use of
                  information following this policy.
                </p>
                <p className="lead mb-5">
                  INFORMATION COLLECTION AND USE
                  <br />
                  While using our site, we may ask you to provide us with
                  certain personally identifiable information that can be used
                  to contact or identify you. Personally identifiable
                  information may include, but is not limited to your name
                  (“Personal Information”).
                </p>
                <p className="lead mb-5">
                  LOG DATA
                  <br />
                  Like many site operators, we collect information that your
                  browser sends whenever you visit our site (“Log Data”). This
                  Log Data may include information such as your computer’s
                  Internet Protocol (“IP”) address, browser type, browser
                  version, the pages of our site that you visit, the time and
                  date of your visit, the time spent on those pages and other
                  statistics. In addition, we may use third-party services such
                  as Google Analytics that collect, monitor and analyze this
                  data.
                </p>
                <p className="lead mb-5">
                  COMMUNICATION
                  <br />
                  We may use your Personal Information to contact you with
                  newsletters, marketing or promotional materials and other
                  information. This is ONLY if you complete our forms, we will
                  never reach out to you without consent.
                </p>
                <p className="lead mb-5">
                  COOKIES
                  <br />
                  Cookies are files with a small amount of data, which may
                  include an anonymous unique identifier. Cookies are sent to
                  your browser from a website and stored on your computer’s hard
                  drive. Like many sites, we use “cookies” to collect
                  information. You can instruct your browser to refuse all
                  cookies or to indicate when a cookie is being sent. However,
                  if you do not accept cookies, you may not be able to use some
                  portions of our Site.
                </p>
                <p className="lead mb-5">
                  SECURITY
                  <br />
                  The security of your Personal Information is important to us,
                  but remember that no method of transmission over the Internet,
                  or method of electronic storage, is 100% secure. While we
                  strive to use commercially acceptable means to protect your
                  Personal Information, we cannot guarantee its absolute
                  security.
                </p>
                <p className="lead mb-5">
                  CHANGES TO THIS POLICY
                  <br />
                  This Privacy Policy is effective as of April 1st, 2024 and
                  will remain in effect except with respect to any changes in
                  its provisions in the future, which will be in effect
                  immediately after being posted on this page. We reserve the
                  right to update or change our Privacy Policy at any time and
                  you should check this Privacy Policy periodically. Your
                  continued use of the Service after we post any modifications
                  to the Privacy Policy on this page will constitute your
                  acknowledgment of the modifications and your consent to abide
                  and be bound by the modified Privacy Policy. If we make any
                  material changes to this Privacy Policy, we will notify you
                  either through the email address you have provided us, or by
                  placing a prominent notice on our website.
                </p>
                <p className="lead mb-0">
                  CONTACT US
                  <br />
                  If you have any questions about this Privacy Policy, please
                  contact us at{" "}
                  <a
                    href="mailto:hello@prolevantstudio.com"
                    style={{ color: "#000" }}
                  >
                    hello@prolevantstudio.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
