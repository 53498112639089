import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import "./HireStyle.css";
import React from "react";
interface hireContext {
  stepNumber: number;
  totalNumber: number;
  changeStepNumber: (number: number) => void;
  transition: () => void;
  transitionForPrevStep: () => void;
  setFormData: React.Dispatch<React.SetStateAction<FormData | undefined>>;
  formData: FormData | undefined;
  questions: questions[];
  setQuestions: React.Dispatch<React.SetStateAction<questions[]>>;
}
const defaultQuestion: questions[] = [
  {
    question: "What kind of developer are you looking for",
    questionNumber: 1,
    totalQuestion: 3,
  },
  {
    question: "Which are the must-have skill that you are looking for",
    questionNumber: 2,
    totalQuestion: 3,
  },
  {
    question: `We found 5 or 8 developers that can help with your work`,
    questionNumber: 3,
    totalQuestion: 3,
  },
];
interface SkillsType {
  value: string;
  label: string;
  isSelected: boolean;
}
interface HireContextProviderPropsType {
  children: ReactNode;
}
interface questions {
  question: string;
  questionNumber: number;
  totalQuestion: number;
}
interface FormData {
  skills: SkillsType[];
  specializaion: string;
  userName: string;
  email: string;
}
const HireContext = createContext<hireContext>({
  stepNumber: 1,
  totalNumber: 1,
  changeStepNumber: () => {},
  transition: () => {},
  transitionForPrevStep: () => {},
  setFormData: () => {},
  formData: undefined,
  questions: defaultQuestion,
  setQuestions: () => {},
});

export const HireContextProvider = ({
  children,
}: HireContextProviderPropsType) => {
  const [stepNumber, setStepNumber] = useState(1);
  const [totalNumber, setTotalNumber] = useState(3);
  const [firstEntaring, setFirstEntaring] = useState(true);
  const initForm = {
    skills: [],
    userName: "",
    email: "",
    specializaion: "",
  };
  const [formData, setFormData] = useState<FormData | undefined>(initForm);
  const [questions, setQuestions] = useState<questions[]>(defaultQuestion);
  const changeStepNumber = (number: number) => {
    setStepNumber(number);
  };
  const transition = () => {
    const element = document.getElementById(`question-${stepNumber - 1}`);
    const Nextelement = document.getElementById(`question-${stepNumber}`);
    element?.classList.add("translate-50");
    element?.classList.add("hide-element");
    Nextelement?.classList.add("translate-50");
    setTimeout(() => {
      element?.classList.remove("translate-50");
      element?.classList.remove("hide-element");
      Nextelement?.classList.remove("translate-50");
    }, 400);
    setTimeout(() => {
      setStepNumber((val) => val + 1);
    }, 500);
  };
  const transitionForPrevStep = () => {
    const element = document.getElementById(`question-${stepNumber - 1}`);
    const Nextelement = document.getElementById(`question-${stepNumber}`);
    element?.classList.add("translate-50");
    element?.classList.add("hide-element");
    Nextelement?.classList.add("translate-50");
    setTimeout(() => {
      element?.classList.remove("translate-50");
      element?.classList.remove("hide-element");
      Nextelement?.classList.remove("translate-50");
    }, 400);
    setTimeout(() => {
      setStepNumber((val) => val - 1);
    }, 500);
  };
  useEffect(() => {
    let dataFromSession = sessionStorage.getItem("hireData");
    if (firstEntaring && !dataFromSession) {
      sessionStorage.setItem("hireData", JSON.stringify(formData));
    } else if (firstEntaring && dataFromSession) {
      setFormData(JSON.parse(dataFromSession));
    } else {
      sessionStorage.setItem("hireData", JSON.stringify(formData));
    }
    setFirstEntaring(false);
  }, [formData]);

  return (
    <HireContext.Provider
      value={{
        setQuestions,
        questions,
        stepNumber,
        totalNumber,
        changeStepNumber,
        transition,
        transitionForPrevStep,
        formData,
        setFormData,
      }}
    >
      {children}
    </HireContext.Provider>
  );
};
export const useHireContext = () => useContext(HireContext);
