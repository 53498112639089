import { Carousel } from "../../components/Carousel";
import CompaniesSliders from "../../components/CompaniesSliders";
import ContentSection from "../../components/ContentSection";
import IntroSection from "../../components/IntroSection";

export default function Home() {
  return (
    <div>
      <IntroSection />
      <CompaniesSliders />
      <ContentSection />
    </div>
  );
}
