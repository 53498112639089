import { useNavigate } from "react-router-dom";
import {
  checkout_basic,
  checkout_pro,
  checkout_team,
} from "../pages/pricing/checkout";
import { useState } from "react";
import { Carousel } from "./Carousel";
import { SwiperSlide } from "swiper/react";
export default function ContentSection() {
  const navigate = useNavigate();
  const [basicPlanLoader, setBasicPlanLoader] = useState(false);
  const [teamPlanLoader, setTeamPlanLoader] = useState(false);
  const [proPlanLoader, setProPlanLoader] = useState(false);
  const onSelectBasicPlan = async () => {
    setBasicPlanLoader(true);
    const response = await checkout_basic();
    window.open(response.data, "_blank");
    setBasicPlanLoader(false);
  };
  const onSelectTeamPlan = async () => {
    setTeamPlanLoader(true);
    const response = await checkout_team();
    window.open(response.data, "_blank");
    setTeamPlanLoader(false);
  };
  const onSelectProPlan = async () => {
    setProPlanLoader(true);
    const response = await checkout_pro();
    window.open(response.data, "_blank");
    setProPlanLoader(false);
  };
  const sliderImages = [
    { image: "images/projects/05.png", alt: "Mac Sunglasses" },
    { image: "images/projects/08.png", alt: "Mac Sunglasses" },
    { image: "images/projects/03.png", alt: "Mac Sunglasses" },
    { image: "images/projects/04.png", alt: "Mac Sunglasses" },
    { image: "images/projects/01.png", alt: "Mac Sunglasses" },
  ];

  //   <article className="portfolio-item">
  //     <div className="grid-inner">
  //       <div className="portfolio-image">
  //         <img
  //           src="images/projects/05.png"
  //           alt="Mac Sunglasses"
  //           style={{ borderRadius: "20px" }}
  //         />
  //       </div>
  //       {/* <!-- <div className="portfolio-desc pb-0">
  //     <h3>
  //       <a className="d-flex" href="index.html" target="_blank">Hyundai&nbsp;|&nbsp;<span>Corporate business website</span></a>
  //     </h3>
  //   </div> --> */}
  //     </div>
  //   </article>

  //   <article className="portfolio-item">
  //     <div className="grid-inner">
  //       <div className="portfolio-image">
  //         <img
  //           src="images/projects/08.png"
  //           alt="Mac Sunglasses"
  //           style={{ borderRadius: "20px" }}
  //         />
  //       </div>
  //     </div>
  //   </article>

  //   <article className="portfolio-item">
  //     <div className="grid-inner">
  //       <div className="portfolio-image">
  //         <img
  //           src="images/projects/03.png"
  //           alt="Mac Sunglasses"
  //           style={{ borderRadius: "20px" }}
  //         />
  //       </div>
  //     </div>
  //   </article>

  //   <article className="portfolio-item">
  //     <div className="grid-inner">
  //       <div className="portfolio-image">
  //         <img
  //           src="images/projects/04.png"
  //           alt="Mac Sunglasses"
  //           style={{ borderRadius: "20px" }}
  //         />
  //       </div>
  //     </div>
  //   </article>

  //   <article className="portfolio-item">
  //     <div className="grid-inner">
  //       <div className="portfolio-image">
  //         <img
  //           src="images/projects/01.png"
  //           alt="Mac Sunglasses"
  //           style={{ borderRadius: "20px" }}
  //         />
  //       </div>
  //     </div>
  //   </article>
  // </div>
  return (
    <section id="content">
      <div className="content-wrap py-0">
        <div id="section-demos" className="section bg-transparent mt-0 pt-0">
          <div className="container">
            <div className="heading-block center border-bottom-0 bottommargin-lg clearfix">
              <h2 className="nott col-12" style={{ color: "#0a0a0a" }}>
                <span style={{ color: "#0a0a0a", fontWeight: "300" }}>
                  Trusted by
                </span>
                100+ leading organisations
              </h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="row justify-content-center prolevant-clients">
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/1.png" />
                  </div>
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/2.png" />
                  </div>
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/3.png" />
                  </div>
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/4.png" />
                  </div>
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/5.png" />
                  </div>
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/6.png" />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row justify-content-center prolevant-clients">
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/7.png" />
                  </div>
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/8.png" />
                  </div>
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/9.png" />
                  </div>
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/10.png" />
                  </div>
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/11.png" />
                  </div>
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/12.png" />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row justify-content-center prolevant-clients">
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/13.png" />
                  </div>
                  {/* <!-- <div className="col-4 col-sm-2 client-item">
                <img src="images/clients/14.png" />
              </div> --> */}
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/15.png" />
                  </div>
                  {/* <!-- <div className="col-4 col-sm-2 client-item">
                <img src="images/clients/16.png" />
              </div> --> */}
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/17.png" />
                  </div>
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/18.png" />
                  </div>
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/19.png" />
                  </div>
                  <div className="col-4 col-sm-2 client-item">
                    <img src="images/clients/20.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section center mt-0 pt-0 clearfix">
          <div className="container-fluid px-5 clearfix">
            <div
              className="row col py-5 justify-content-center"
              style={{
                backgroundColor: " rgba(0, 0, 0, 0.08)",
                borderRadius: "24px",
              }}
            >
              <div className="col-md-10">
                <div className="heading-block center border-bottom-0 bottommargin-sm clearfix">
                  <h2 className="nott col-12" style={{ color: "#0a0a0a" }}>
                    Your affordable & flexible development team
                  </h2>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <h2 className="service-title">Subscribe</h2>
                    <p className="service-desc fs-6">
                      Subscribe to a plan according to your development need
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h2 className="service-title">
                      Work with top global talent
                    </h2>
                    <p className="service-desc fs-6">
                      We will build a team of developers, designers based on
                      your requests
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h2 className="service-title">No Contracts</h2>
                    <p className="service-desc fs-6">
                      Stop whenever you want. No contracts or commitments!
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a href="/#/hire" className="button button-circle button-dark">
                  Get started
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          id="section-multipage"
          className="section page-section center bg-transparent mt-0 clearfix pt-0"
        >
          <div>
            <div className="col-xs-12 col-md-8 offset-md-2 heading-block center border-bottom-0 clearfix px-5">
              <h2 className="nott col-12" style={{ color: "#0a0a0a" }}>
                We build websites and products to supercharge your business
              </h2>
            </div>
            <div className="container d-flex flex-wrap justify-content-center mb-5 projects-tags">
              <div>
                <a
                  href="#"
                  className="button button-border button-circle button-dark"
                >
                  AI applications
                </a>
              </div>
              <div>
                <a
                  href="#"
                  className="button button-border button-circle button-dark"
                >
                  E-commerce platforms
                </a>
              </div>
              <div>
                <a
                  href="#"
                  className="button button-border button-circle button-dark"
                >
                  SaaS applications
                </a>
              </div>
              <div>
                <a
                  href="#"
                  className="button button-border button-circle button-dark"
                >
                  Tracking systems
                </a>
              </div>
              <div>
                <a
                  href="#"
                  className="button button-border button-circle button-dark"
                >
                  Blockchain projects
                </a>
              </div>
              <div>
                <a
                  href="#"
                  className="button button-border button-circle button-dark"
                >
                  Mobile apps
                </a>
              </div>
              <div>
                <a
                  href="#"
                  className="button button-border button-circle button-dark"
                >
                  Custom web apps
                </a>
              </div>
              <div>
                <a
                  href="#"
                  className="button button-border button-circle button-dark"
                >
                  Data Tools
                </a>
              </div>
              <div>
                <a
                  href="#"
                  className="button button-border button-circle button-dark"
                >
                  Websites
                </a>
              </div>
              <div>
                <a
                  href="#"
                  className="button button-border button-circle button-dark"
                >
                  Archiving systems
                </a>
              </div>
            </div>
            <div style={{}}>
              <Carousel slidesPerView={1.8} spaceBetween={20}>
                {sliderImages.map((item, index) => (
                  <SwiperSlide>
                    <img
                      src={item.image}
                      alt={item.alt}
                      className="swiper-slide-image-item"
                    />
                  </SwiperSlide>
                ))}
              </Carousel>
            </div>
            <div className="clear"></div>
          </div>
          <div className="text-center">
            <a
              href="/#/work"
              // onClick={() => {
              //   navigate("/work");
              // }}
              className="button button-light button-large mt-5"
              style={{ backgroundColor: "transparent" }}
            >
              See More Work <i className="icon-line-chevron-right"></i>
            </a>
          </div>
        </div>
        {/* <!-- .portfolio-carousel end --> */}
        <div
          id="section-blocks"
          className="section page-section bg-transparent mt-0"
          style={{ backgroundImage: "none" }}
        >
          <div className="container">
            <div className="heading-block center border-bottom-0 mw-md mx-auto">
              <h2
                className="nott mb-4"
                style={{
                  fontSize: "calc(1.2rem + 1.7vw)",
                  letterSpacing: "-1px",
                  fontWeight: "400",
                }}
              >
                Dedicated expert talents for your project
              </h2>
              <p className="lead mb-5" style={{ color: "#000000" }}>
                We've an experienced team of over 70+ developers, designers,
                project managers and more. No matter the scale or complexity of
                your project needs, we have the dedicated specialists required
                for complete success.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-4 px-4 py-3">
                <ul
                  style={{
                    listStyleType: "none",
                    fontSize: "26px",
                    color: "#0a0a0a",
                  }}
                >
                  <li>Web Development</li>
                  <li>Frontend Development</li>
                  <li>UI/UX Design</li>
                  <li>Graphic Designer</li>
                  <li>Software Architect</li>
                  <li>Team Leader</li>
                </ul>
              </div>
              <div className="col-lg-4 px-4 py-3">
                <ul
                  style={{
                    listStyleType: "none",
                    fontSize: "26px",
                    color: "#0a0a0a",
                  }}
                >
                  <li>Mobile App Native</li>
                  <li>Web 3.0</li>
                  <li>Quality Assurance</li>
                  <li>Cyber Security</li>
                  <li>System Analysis</li>
                  <li>Project Manager</li>
                </ul>
              </div>
              <div className="col-lg-4 px-4 py-3">
                <ul
                  style={{
                    listStyleType: "none",
                    fontSize: "26px",
                    color: "#0a0a0a",
                  }}
                >
                  <li>Backend Development</li>
                  <li>Artificial Intelligence</li>
                  <li>Data Analytics</li>
                  <li>Cloud Services</li>
                  <li>System Design</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section center pt-0 mt-0 clearfix">
          <div className="px-5 container-fluid clearfix">
            <div
              className="row col justify-content-center pt-5 pt-xs-0"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                borderRadius: "24px",
              }}
            >
              <div className="col-md-9">
                <h1
                  className="pb-3"
                  style={{ fontWeight: "400", color: "black" }}
                >
                  Say goodbye to long proposals and complex contracts!
                </h1>
                <p className="lead" style={{ color: "#0a0a0a" }}>
                  Prolevant provides on-demand senior global talent with
                  transparent and affordable services.
                  <br />
                  We offer flexible monthly development packages for{" "}
                  <span style={{ fontWeight: "700" }}>
                    70% cheaper than industry average.
                  </span>
                </p>
              </div>
              <div className="col-md-11">
                <div className="row pricing col mb-4">
                  <div className="col-md-6 col-lg-3">
                    <div
                      className="pricing-box pricing-simple p-4 bg-dark text-start text-white"
                      style={{
                        borderRadius: "24px",
                        border: "4px solid #ffffff00",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div className="pricing-title">
                          <h3 style={{ color: "#ffffff" }}>Basic</h3>
                        </div>
                        <div className="pricing-price">
                          <span className="price-unit">$3,000</span>
                          <span className="price-tenure">per month</span>
                        </div>
                        <p
                          className=""
                          style={{ textAlign: "justify", fontSize: "14px" }}
                        >
                          Perfect for teams that needs a single developer
                          support
                        </p>
                        <div
                          className="pricing-features"
                          style={{
                            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                          }}
                        >
                          <ul className="iconlist">
                            <li>
                              <i className="icon-check text-smaller"></i>1
                              developer ($17.31/hour)
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>40
                              hours of development work weekly
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>Select
                              any development niche
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>Cancel
                              anytime
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="pricing-action pb-0">
                        <button
                          id="basic-plan-button"
                          onClick={onSelectBasicPlan}
                          className="button button-border button-circle button-dark w-100 text-center btn-lg"
                          style={{
                            border: "2px solid #ffffff",
                            color: "#ffffff",
                          }}
                        >
                          {!basicPlanLoader ? (
                            "Get started"
                          ) : (
                            <i className="icon-spinner icon-spin"></i>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div
                      className="pricing-box pricing-simple p-4 bg-dark text-start text-white"
                      style={{
                        borderRadius: "24px",
                        border: "4px solid #ffffff00",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div className="pricing-title">
                          <h3 style={{ color: "#ffffff" }}>Team</h3>
                        </div>
                        <div className="pricing-price">
                          <span className="price-unit">$8,000</span>
                          <span className="price-tenure">per month</span>
                        </div>
                        <p
                          className=""
                          style={{ textAlign: "justify", fontSize: "14px" }}
                        >
                          Perfect for businesses that needs a development team
                        </p>
                        <div
                          className="pricing-features"
                          style={{
                            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                          }}
                        >
                          <ul className="iconlist">
                            <li>
                              <i className="icon-check text-smaller"></i>3
                              developer ($15.38/hour)
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>120
                              hours of development work weekly
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>We
                              build and manage your team
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>24/7
                              support
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>Cancel
                              anytime
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="pricing-action pb-0">
                        <button
                          id="team-plan-button"
                          onClick={onSelectTeamPlan}
                          className="button button-border button-circle button-dark w-100 text-center btn-lg"
                          style={{
                            border: "2px solid #ffffff",
                            color: "#ffffff",
                          }}
                        >
                          {!teamPlanLoader ? (
                            "Get started"
                          ) : (
                            <i className="icon-spinner icon-spin"></i>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div
                      className="pricing-box pricing-simple p-4 bg-dark text-start text-white"
                      style={{
                        borderRadius: "24px",
                        border: "4px solid #ffffffff",
                        height: " 100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div className="pricing-title">
                          <h3 style={{ color: "#ffffff" }}>
                            Pro
                            <span
                              className="badge rounded-pill"
                              style={{
                                float: "right",
                                backgroundColor: "#ffffff",
                                color: "#000000",
                              }}
                            >
                              MOST POPULAR
                            </span>
                          </h3>
                        </div>
                        <div className="pricing-price">
                          <span className="price-unit">$15,000</span>
                          <span className="price-tenure">per month</span>
                        </div>
                        <p
                          className=""
                          style={{ textAlign: "justify", fontSize: "14px" }}
                        >
                          Perfect for businesses who needs a dedicated dev team
                          and fast delivery
                        </p>
                        <div
                          className="pricing-features"
                          style={{
                            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                          }}
                        >
                          <ul className="iconlist">
                            <li>
                              <i className="icon-check text-smaller"></i>6
                              developer ($14.42/hour)
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>240
                              hours of development work weekly
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>We
                              build and manage your team
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>24/7
                              support
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>Cancel
                              anytime
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="pricing-action pb-0">
                        <button
                          id="pro-plan-button"
                          onClick={onSelectProPlan}
                          className="button button-border button-circle button-dark w-100 text-center btn-lg"
                          style={{
                            border: "2px solid #ffffff",
                            color: "#ffffff",
                          }}
                        >
                          {!proPlanLoader ? (
                            "Get started"
                          ) : (
                            <i className="icon-spinner icon-spin"></i>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div
                      className="pricing-box pricing-simple p-4 bg-dark text-start text-white"
                      style={{
                        borderRadius: "24px",
                        border: "4px solid #ffffff00",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: " space-between",
                      }}
                    >
                      <div>
                        <div className="pricing-title">
                          <h3 style={{ color: "#ffffff" }}>Custom</h3>
                        </div>
                        <div className="pricing-price">
                          <span className="price-unit">Custom pricing</span>
                          <span className="price-tenure">&nbsp;</span>
                        </div>
                        <p
                          className=""
                          style={{ textAlign: "justify", fontSize: "14px" }}
                        >
                          Do you have a complex projects that needs custom
                          support? Book a call with us today!
                        </p>
                        <div
                          className="pricing-features"
                          style={{
                            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                          }}
                        >
                          <ul className="iconlist">
                            <li>
                              <i className="icon-check text-smaller"></i>Custom
                              team to deliver complex projects
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>Fast
                              delivery according to your timeline
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>24/7
                              support
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>Cancel
                              anytime
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="pricing-action pb-0">
                        <a
                          href="https://meetings-eu1.hubspot.com/prolevant"
                          target="_blank"
                          className="button button-circle button-light w-100 text-center btn-lg"
                        >
                          Book a call
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section pt-0 mt-0 clearfix">
          <div className="px-5 container-fluid clearfix">
            <div className="row">
              <div
                className="promo promo-light p-4 p-md-5 mb-5 text-center text-md-start"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                  borderRadius: "24px",
                }}
              >
                <div className="row align-items-center">
                  <div className="col-12 col-lg">
                    <h2 className="pb-0 mb-0" style={{ color: "black" }}>
                      Want to talk with us first?
                    </h2>
                    <p className="lead mb-0">
                      Learn more about how we work and how we can help you
                      during a free call.
                    </p>
                  </div>
                  <div className="col-12 col-lg-auto mt-4 mt-lg-0">
                    <a
                      href="https://meetings-eu1.hubspot.com/prolevant"
                      target="_blank"
                      className="button button-circle button-dark"
                    >
                      Book a call
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
