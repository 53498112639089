import { Route, HashRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/home";
import Pricing from "./pages/pricing";
import Work from "./pages/work";
import HireTalent from "./pages/hire";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import App from "./App";
import NotFoundPage from "./pages/404";
import "./css/animate.css";
import "./css/bootstrap.css";
import "./css/custom.css";
import "./css/dark.css";
import "./css/font-icons.css";
import "./css/intro-fonts.css";
import "./css/magnific-popup.css";
import "./style.css";
// export const router = createBrowserRouter([
//   {
//     path: "/",
//     element: (
//       <App>
//         <Home />
//       </App>
//     ),
//   },
//   {
//     path: "/pricing",
//     element: (
//       <App>
//         <Pricing />
//       </App>
//     ),
//   },
//   {
//     path: "/work",
//     element: (
//       <App>
//         <Work />
//       </App>
//     ),
//   },
//   {
//     path: "/hire",
//     element: <HireTalent />,
//   },
//   {
//     path: "/privacy",
//     element: (
//       <App>
//         <Privacy />
//       </App>
//     ),
//   },
//   {
//     path: "/terms",
//     element: (
//       <App>
//         <Terms />
//       </App>
//     ),
//   },
// ]);

export default function AppRouter() {
  return (
    <Router basename="/">
      <Routes>
        <Route
          index
          path="/"
          element={
            <App>
              <Home />
            </App>
          }
        />
        <Route
          path="/pricing"
          element={
            <App>
              <Pricing />
            </App>
          }
        />
        <Route
          path="/work"
          element={
            <App>
              <Work />
            </App>
          }
        />
        <Route element={<HireTalent />} path="/hire" />
        <Route
          path="/privacy"
          element={
            <App>
              <Privacy />
            </App>
          }
        />
        <Route
          path="/Terms"
          element={
            <App>
              <Terms />
            </App>
          }
        />
        <Route
          path="*"
          element={
            <App>
              <NotFoundPage />
            </App>
          }
        />
      </Routes>
    </Router>
  );
}
