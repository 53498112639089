import Header from "./components/Header";
import "./App.css";
import Footer from "./components/Footer";
interface AppPropsType {
  children: React.ReactNode;
}
function App({ children }: AppPropsType) {
  return (
    <>
      <div>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-NNB3FDF3"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
        <div id="wrapper" className="clearfix">
          <Header />
          {children}
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
