import { useNavigate } from "react-router-dom";
import "../HireStyle.css";
interface HeaderLogoPropsType {}
export default function HeaderLogo({}: HeaderLogoPropsType) {
  const navigate = useNavigate();
  return (
    <div className="w-100">
      <div
        style={{
          marginBlockEnd: "3rem",
          fontSize: "40px",
          color: "white",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        Prolevant
      </div>
    </div>
  );
}
