import PricingContnet from "./components/PricingContent";
import PricingIntroSection from "./components/PricingIntroSection";

export default function Pricing() {
  return (
    <div>
      <PricingIntroSection />
      <PricingContnet />
    </div>
  );
}
