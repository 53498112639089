import { ReactNode, useEffect, useState } from "react";
import "../HireStyle.css";
interface CardForMultiPropsType {
  src: string;
  title: string;
  isSelected?: boolean;
  onClick?: () => void;
}

export default function CardForMulti({
  src,
  title,
  isSelected,
  onClick,
}: CardForMultiPropsType) {
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    if (isSelected !== undefined) {
      setIsActive(isSelected);
    }
  }, [isSelected]);
  return (
    <div
      onClick={() => {
        setIsActive((value) => !value);
        onClick && onClick();
      }}
      className={`hire-skill-card   ${
        isActive ? "hire-skill-card-active" : ""
      }`}
      style={{}}
    >
      <img
        src={src}
        alt={title}
        className={`${title === "JavaScript" ? "js-file" : ""}`}
      />

      {title}
    </div>
  );
}
