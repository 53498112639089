import axios from "axios";
const baseApiUrl = "https://stripe.prolevantstudio.com/";
const paymentBackendUrl = `${baseApiUrl}Stripe/session`;
// Local
// const successUrl = "http://localhost:8080/";
// online Test
const successUrl = "https://web.prolevantstudio.com/";

export const checkout_basic = async () => {
  const temp = await axios.post(
    paymentBackendUrl,
    {
      amount: 3000,
      productName: "Basic",
      successUrl: successUrl,
    },
    {
      headers: {
        keyCode: "1218221010#$",
      },
    }
  );
  return temp;
};

export const checkout_team = async () => {
  const temp = await axios.post(
    paymentBackendUrl,
    {
      amount: 8000,
      productName: "Team",
      successUrl: successUrl,
    },
    {
      headers: {
        keyCode: "1218221010#$",
      },
    }
  );
  return temp;
};
export const checkout_pro = async () => {
  const temp = await axios.post(
    paymentBackendUrl,
    {
      amount: 15000,
      productName: "Pro",
      successUrl: successUrl,
    },
    {
      headers: {
        keyCode: "1218221010#$",
      },
    }
  );
  return temp;
};
