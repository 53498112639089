import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
export default function Header() {
  const headerRef = useRef<HTMLUListElement>(null);
  // menufunctions: function(){

  //   let menuItemSubs		= $( '.menu-item:has(.sub-menu-container)' ),
  //     menuItemSubsLinks	= menuItemSubs.children( '.menu-link' ),
  //     submenusT			= '.mega-menu-content, .sub-menu-container',
  //     submenus			= $( submenusT ),
  //     menuItemT			= '.menu-item',
  //     subMenuT			= '.sub-menu',
  //     menuSpeed			= primaryMenu.attr( 'data-trigger-speed' ) || 200,
  //     subMenuTriggerT		= '.sub-menu-trigger',
  //     menuItemTrigger;

  //   menuSpeed = Number( menuSpeed );

  //   menuItemTrigger	= menuItemSubs.children( subMenuTriggerT );

  //   if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) {
  //     setTimeout( function(){
  //       if( $headerWrapClone.length > 0 ) {
  //         $headerWrapClone.css({ 'height': initHeaderHeight });
  //       }
  //       SEMICOLON.header.includeOffset();
  //     }, 1000);
  //     primaryMenu.find( submenus ).css({ 'display': '' });
  //   } else {
  //     $headerInc.css({ 'margin-top': '' });
  //   }

  //   if( ( $body.hasClass('overlay-menu') && primaryMenu.hasClass('on-click') ) && ( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) ) {
  //     menuItemSubsLinks.off( 'click' ).on( 'click', function(e){
  //       let triggerEl = $(this);
  //       triggerEl.parents( subMenuT ).siblings().find( submenus ).stop( true, true ).slideUp( menuSpeed );
  //       triggerEl.parent( menuItemT ).children( submenusT ).stop( true, true ).slideToggle( menuSpeed );
  //       e.preventDefault();
  //     });
  //   } else if( ( $body.hasClass('side-header') && primaryMenu.hasClass('on-click') ) || ( $body.hasClass('device-md') || $body.hasClass('device-sm') || $body.hasClass('device-xs') ) ) {
  //     menuItemTrigger.removeClass('icon-rotate-90');
  //     $( menuItemT ).find( submenus ).filter(':not(:animated)').stop( true, true ).slideUp( menuSpeed , function(){
  //       $body.toggleClass("primary-menu-open", false);
  //     });

  //     menuItemTrigger = menuItemTrigger.add( menuItemSubsLinks.filter('[href^="#"]') );

  //     menuItemTrigger.off( 'click' ).on( 'click', function(e){
  //       let triggerEl = $(this);
  //       triggerEl.parents( subMenuT ).siblings().find( subMenuTriggerT ).removeClass('icon-rotate-90');
  //       triggerEl.parents( subMenuT ).siblings().find( submenus ).filter(':not(:animated)').stop( true, true ).slideUp( menuSpeed );
  //       triggerEl.parent( menuItemT ).children( submenusT ).filter(':not(:animated)').stop( true, true ).slideToggle( menuSpeed );

  //       let subMenuTriggerEl = triggerEl.parent( menuItemT ).children( subMenuTriggerT );

  //       if( !subMenuTriggerEl.hasClass( 'icon-rotate-90' ) ) {
  //         subMenuTriggerEl.addClass('icon-rotate-90');
  //       } else {
  //         subMenuTriggerEl.removeClass('icon-rotate-90');
  //       }

  //       e.preventDefault();
  //     });
  //   } else if( ( $body.hasClass('overlay-menu') || $body.hasClass('side-header') ) && ( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) ) {
  //     primaryMenu.find( submenus ).stop( true, true ).slideUp( menuSpeed );
  //     $( menuItemT ).hover( function(e){
  //       $(this).children( submenusT ).stop( true, true ).slideDown( menuSpeed );
  //     }, function(){
  //       $(this).children( submenusT ).stop( true, true ).slideUp( menuSpeed );
  //     });
  //   } else {
  //     if( primaryMenu.hasClass('on-click') ) {
  //       menuItemSubsLinks.off( 'click' ).on( 'click', function(e){
  //         let triggerEl = $(this);
  //         triggerEl.parents( subMenuT ).siblings().find( submenus ).removeClass('d-block');
  //         triggerEl.parent( menuItemT ).children( submenusT ).toggleClass('d-block');
  //         triggerEl.parent( menuItemT ).siblings().removeClass('current');
  //         triggerEl.parent( menuItemT ).toggleClass('current');
  //         e.preventDefault();
  //       });
  //     }
  //   }

  //   if( $('.top-links').hasClass('on-click') || ( $body.hasClass('device-md') || $body.hasClass('device-sm') || $body.hasClass('device-xs') ) ) {
  //     $('.top-links-item:has(.top-links-sub-menu,.top-links-section) > a').on( 'click', function(e){
  //       $(this).parents('li').siblings().find('.top-links-sub-menu,.top-links-section').removeClass('d-block');
  //       $(this).parent('li').children('.top-links-sub-menu,.top-links-section').toggleClass('d-block');
  //       e.preventDefault();
  //     });
  //   }

  //   SEMICOLON.header.menuInvert( $('.top-links-section') );

  //   $('#primary-menu-trigger').off( 'click' ).on( 'click', function() {
  //     if( $body.hasClass('device-md') || $body.hasClass('device-sm') || $body.hasClass('device-xs') ) {
  //       if( primaryMenu.find('.mobile-primary-menu').length > 0 ) {
  //         $( '.primary-menu:not(.mobile-menu-off-canvas) .mobile-primary-menu' ).stop( true, true ).slideToggle( menuSpeed );
  //         $( '.primary-menu.mobile-menu-off-canvas .mobile-primary-menu' ).toggleClass('d-block');
  //       } else {
  //         $( '.primary-menu:not(.mobile-menu-off-canvas) .menu-container' ).stop( true, true ).slideToggle( menuSpeed );
  //         $( '.primary-menu.mobile-menu-off-canvas .menu-container' ).toggleClass('d-block');
  //       }
  //     }
  //     $body.toggleClass("primary-menu-open");
  //     return false;
  //   });

  //   $( '.menu-container:not(.mobile-primary-menu)' ).css({ 'display': '' });
  //   if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) {
  //     primaryMenu.find('.mobile-primary-menu').removeClass('d-block');
  //   }

  // },
  const navigate = useNavigate();
  const [openMenuOnMobile, setOpenMenuOnMobile] = useState(false);

  return (
    <header id="header" className="full-header dark">
      <div id="header-wrap">
        <div className="container">
          <div className="header-row">
            <div id="logo">
              <a
                href="/"
                onClick={() => {
                  // navigate("/");
                  setOpenMenuOnMobile(false);
                }}
                style={{
                  cursor: "pointer",
                }}
                className="standard-logo"
                data-dark-logo="/images/logo-dark.png"
              >
                Prolevant
              </a>
              <a
                href="/"
                onClick={() => {
                  // navigate("/");
                  setOpenMenuOnMobile(false);
                }}
                style={{ color: "#000", cursor: "pointer" }}
                className="retina-logo"
                data-dark-logo="/images/logo-dark.png"
              >
                Prolevant
              </a>
            </div>
            {/* {!openMenuOnMobile && ( */}
            <div
              id="primary-menu-trigger"
              className={`${openMenuOnMobile ? "primary-menu-open" : ""}`}
              onClick={() => {
                setOpenMenuOnMobile((val) => !val);
              }}
            >
              <svg className="svg-trigger" viewBox="0 0 100 100">
                <path
                  style={{ stroke: "#000" }}
                  d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
                ></path>
                <path style={{ stroke: "#000" }} d="m 30,50 h 40"></path>
                <path
                  style={{ stroke: "#000" }}
                  d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
                ></path>
              </svg>
            </div>
            {/* )} */}
            {/* {openMenuOnMobile && (
              <div
                id="primary-menu-trigger"
                style={{
                  color: "black",
                  fontSize: "2rem",
                  width: "50px",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  setOpenMenuOnMobile(false);
                }}
              >
                <IoIosClose />
              </div>
            )} */}

            <nav className="primary-menu">
              <ul
                style={{ maxHeight: "0px" }}
                ref={headerRef}
                className={`menu-container ${
                  openMenuOnMobile ? "dispaly-block open-on-mobile" : ""
                }`}
              >
                <li
                  className="menu-item"
                  onClick={() => {
                    setOpenMenuOnMobile(false);
                  }}
                >
                  <a
                    className="menu-link"
                    href="/#/work"
                    // onClick={() => {
                    //   navigate("/work");
                    // }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div>Work</div>
                  </a>
                </li>
                {/* <li className="menu-item">
                  <a
                    className="menu-link"
                    href="/#/hire"
                    // onClick={() => {
                    //   navigate("/hire");
                    // }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div>Hire</div>
                  </a>
                </li> */}
                <li
                  className="menu-item"
                  onClick={() => {
                    setOpenMenuOnMobile(false);
                  }}
                >
                  <a
                    className="menu-link"
                    href="/#/pricing"
                    // onClick={() => {
                    //   navigate("/pricing");
                    // }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div>Pricing</div>
                  </a>
                </li>
                <li
                  className="menu-item mega-menu"
                  onClick={() => {
                    setOpenMenuOnMobile(false);
                  }}
                >
                  <a
                    className="menu-link"
                    href="mailto:hello@prolevantstudio.com"
                  >
                    <div>Contact</div>
                  </a>
                </li>

                <li className="menu-item mega-menu d-none d-lg-block">
                  <a
                    // onClick={() => {
                    //   navigate("/pricing");
                    // }}

                    href="/#/hire"
                    style={{
                      cursor: "pointer",
                    }}
                    className="button btn btn-dark btn-rounded button-circle px-3"
                  >
                    Get started
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}
