import { useHireContext } from "../HireContext";
import "../HireStyle.css";
import Step1 from "./answer/Step1";
import Step2 from "./answer/Step2";
import Step3 from "./answer/Step3";

interface AnswerSidePropsType {}
export default function AnswerSide({}: AnswerSidePropsType) {
  const { stepNumber } = useHireContext();
  return (
    <div className="background-AnswerSide w-50 d-flex items-center answer-padding">
      {stepNumber === 1 && <Step1 />}
      {stepNumber === 2 && <Step2 />}
      {stepNumber === 3 && <Step3 />}
    </div>
  );
}
