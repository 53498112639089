import { useState } from "react";
import { checkout_basic, checkout_pro, checkout_team } from "../checkout";
export default function PricingContnet() {
  const [basicPlanLoader, setBasicPlanLoader] = useState(false);
  const [teamPlanLoader, setTeamPlanLoader] = useState(false);
  const [proPlanLoader, setProPlanLoader] = useState(false);

  const onSelectBasicPlan = async () => {
    setBasicPlanLoader(true);
    const response = await checkout_basic();
    window.open(response.data, "_blank");
    setBasicPlanLoader(false);
  };
  const onSelectTeamPlan = async () => {
    setTeamPlanLoader(true);
    const response = await checkout_team();
    window.open(response.data, "_blank");
    setTeamPlanLoader(false);
  };
  const onSelectProPlan = async () => {
    setProPlanLoader(true);
    const response = await checkout_pro();
    window.open(response.data, "_blank");
    setProPlanLoader(false);
  };
  return (
    <section id="content">
      <div className="content-wrap py-0">
        {/* <!-- Page Sub Menu
              ============================================= --> */}
        <div className="section center pt-0 mt-0 clearfix">
          <div className="px-5 container-fluid clearfix">
            <div className="row col justify-content-center">
              <div className="col-md-11">
                <div className="row pricing col-mb-30 mb-4">
                  <div className="col-md-6 col-lg-3">
                    <div
                      className="pricing-box pricing-simple p-4 bg-dark text-start text-white"
                      style={{
                        borderRadius: "24px",
                        border: "4px solid #FFFFFF00",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div className="pricing-title">
                          <h3 style={{ color: "#ffffff" }}>Basic</h3>
                        </div>
                        <div className="pricing-price">
                          <span className="price-unit">$3,000</span>
                          <span className="price-tenure">per month</span>
                        </div>
                        <p className="" style={{ textAlign: "justify" }}>
                          Perfect for teams that needs a single developer
                          support
                        </p>
                        <div
                          className="pricing-features"
                          style={{
                            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                          }}
                        >
                          <ul className="iconlist">
                            <li>
                              <i className="icon-check text-smaller"></i>1
                              developer ($17.31/hour)
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>40
                              hours of development work weekly
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>Select
                              any development niche
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>Cancel
                              anytime
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="pricing-action pb-0">
                        <button
                          id="basic-plan-button"
                          onClick={onSelectBasicPlan}
                          className="button button-border button-circle button-dark w-100 text-center btn-lg"
                          style={{
                            border: "2px solid #ffffff",
                            color: "#ffffff",
                          }}
                        >
                          {!basicPlanLoader ? (
                            "Get started"
                          ) : (
                            <i className="icon-spinner icon-spin"></i>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div
                      className="pricing-box pricing-simple p-4 bg-dark text-start text-white"
                      style={{
                        borderRadius: "24px",
                        border: "4px solid #FFFFFF00",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div className="pricing-title">
                          <h3 style={{ color: "#ffffff" }}>Team</h3>
                        </div>
                        <div className="pricing-price">
                          <span className="price-unit">$8,000</span>
                          <span className="price-tenure">per month</span>
                        </div>
                        <p className="" style={{ textAlign: "justify" }}>
                          Perfect for businesses that needs a development team
                        </p>
                        <div
                          className="pricing-features"
                          style={{
                            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                          }}
                        >
                          <ul className="iconlist">
                            <li>
                              <i className="icon-check text-smaller"></i>3
                              developer ($15.38/hour)
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>120
                              hours of development work weekly
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>We
                              build and manage your team
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>24/7
                              support
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>Cancel
                              anytime
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="pricing-action pb-0">
                        <button
                          id="team-plan-button"
                          onClick={onSelectTeamPlan}
                          className="button button-border button-circle button-dark w-100 text-center btn-lg"
                          style={{
                            border: "2px solid #ffffff",
                            color: "#ffffff",
                          }}
                        >
                          {!teamPlanLoader ? (
                            "Get started"
                          ) : (
                            <i className="icon-spinner icon-spin"></i>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div
                      className="pricing-box pricing-simple p-4 bg-dark text-start text-white"
                      style={{
                        borderRadius: "24px",
                        border: "4px solid #ffffffff",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div className="pricing-title">
                          <h3 style={{ color: "#ffffff" }}>
                            Pro
                            <span
                              className="badge rounded-pill"
                              style={{
                                float: "right",
                                backgroundColor: "#ffffff",
                                color: "#000000",
                              }}
                            >
                              MOST POPULAR
                            </span>
                          </h3>
                        </div>
                        <div className="pricing-price">
                          <span className="price-unit">$15,000</span>
                          <span className="price-tenure">per month</span>
                        </div>
                        <p className="" style={{ textAlign: "justify" }}>
                          Perfect for businesses who needs a dedicated dev team
                          and fast delivery
                        </p>
                        <div
                          className="pricing-features"
                          style={{
                            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                          }}
                        >
                          <ul className="iconlist">
                            <li>
                              <i className="icon-check text-smaller"></i>6
                              developer ($14.42/hour)
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>240
                              hours of development work weekly
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>We
                              build and manage your team
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>24/7
                              support
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>Cancel
                              anytime
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="pricing-action pb-0">
                        <button
                          id="pro-plan-button"
                          onClick={onSelectProPlan}
                          className="button button-border button-circle button-dark w-100 text-center btn-lg"
                          style={{
                            border: "2px solid #ffffff",
                            color: "#ffffff",
                          }}
                        >
                          {!proPlanLoader ? (
                            "Get started"
                          ) : (
                            <i className="icon-spinner icon-spin"></i>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div
                      className="pricing-box pricing-simple p-4 bg-dark text-start text-white"
                      style={{
                        borderRadius: "24px",
                        border: "4px solid #FFFFFF00",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div className="pricing-title">
                          <h3 style={{ color: "#ffffff" }}>Custom</h3>
                        </div>
                        <div className="pricing-price">
                          <span className="price-unit">Custom pricing</span>
                          <span className="price-tenure">&nbsp;</span>
                        </div>
                        <p className="" style={{ textAlign: "justify" }}>
                          Do you have a complex projects that needs custom
                          support? Book a call with us today!
                        </p>
                        <div
                          className="pricing-features"
                          style={{
                            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                          }}
                        >
                          <ul className="iconlist">
                            <li>
                              <i className="icon-check text-smaller"></i>Custom
                              team to deliver complex projects
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>Fast
                              delivery according to your timeline
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>24/7
                              support
                            </li>
                            <li>
                              <i className="icon-check text-smaller"></i>Cancel
                              anytime
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="pricing-action pb-0">
                        <a
                          href="https://meetings-eu1.hubspot.com/prolevant"
                          target="_blank"
                          className="button button-circle button-light w-100 text-center btn-lg"
                        >
                          Book a call
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section pt-0 mt-0 clearfix">
          <div className="px-5 container-fluid clearfix">
            <div
              className=""
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                borderRadius: "24px",
              }}
            >
              <div
                className="promo promo-light p-4 p-md-5 mb-5"
                style={{ backgroundColor: "transparent" }}
              >
                <div className="row align-items-center">
                  <div className="col-12 col-lg">
                    <h2 className="pb-0 mb-0" style={{ color: "black" }}>
                      Want to talk with us first?
                    </h2>
                    <p
                      className="mb-0"
                      style={{ fontSize: "24px", color: "#0A0A0A" }}
                    >
                      Learn more about how we work and how we can help you
                      during a free call.
                    </p>
                  </div>
                  <div className="col-12 col-lg-auto mt-4 mt-lg-0">
                    <a
                      href="https://meetings-eu1.hubspot.com/prolevant"
                      target="_blank"
                      className="button button-large button-circle button-dark m-0 px-5"
                    >
                      Book a call
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
