interface QuestionPropsType {
  question: string;
  questionNumber: number;
  totalQuestion: number;
  isDesabled?: boolean;
}
export default function Question(props: QuestionPropsType) {
  const { question, questionNumber, totalQuestion, isDesabled = false } = props;
  return (
    <div className={`${isDesabled ? "text-desabled" : "text-white"}`}>
      <small>
        {questionNumber}/{totalQuestion}
      </small>
      <p
        className={`question-h1 ${
          isDesabled ? "text-desabled smaller" : "text-white "
        }`}
      >
        {question}
        {questionNumber === 3 ? "" : "?"}
      </p>
    </div>
  );
}
