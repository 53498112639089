export default function Terms() {
  return (
    <section id="content">
      <div className="content-wrap py-0">
        <div className="section mt-0 pt-0 clearfix">
          <div className="container-fluid px-5 clearfix">
            <div className="row col mt-5 mt-md-0">
              <div className="col-md-8 offset-md-2">
                <div className="heading-block border-bottom-0 bottommargin-sm clearfix">
                  <h2 className="nott col-12" style={{ color: "#0A0A0A" }}>
                    Terms
                  </h2>
                </div>
              </div>
              <div className="col-md-8 offset-md-2">
                <p className="lead mb-5">
                  Please read these Subscription Terms carefully before
                  purchasing our development services from Prolevant Technology
                  ("Prolevant") located at Office No. 418, Building: Hadi Samir
                  Hamoud - Bur Dubai - Al Barsha South 3. By making a purchase
                  through our website, you agree to be bound by these terms. If
                  you object to anything in the terms or otherwise do not
                  understand or agree to bound by these terms, do not use this
                  platform and exit immediately, as you are authorised to use
                  this platform.
                </p>
                <ol style={{ listStylePosition: "inside" }}>
                  <li className="lead mb-4">
                    Subscription Agreement: By purchasing our development
                    services, you enter into a subscription agreement with
                    Prolevant. This agreement governs the terms and conditions
                    of the subscription.
                  </li>
                  <li className="lead mb-4">
                    Service Description: Prolevant offers development services
                    as described on our website. We provide three subscription
                    packages: Basic, Team, and Pro. Each package offers a
                    specific number of development hours per month, as outlined
                    on our pricing page.
                  </li>
                  <li className="lead mb-4">
                    Pricing and Payment: The pricing for each subscription
                    package is clearly stated on our website. You agree to pay
                    the specified monthly fee for the selected package. All
                    prices are in USD (United States Dollars). Payment can be
                    made through Stripe.
                  </li>
                  <li className="lead mb-4">
                    Subscription Duration: Your subscription begins immediately
                    upon successful payment and continues on a monthly basis.
                    There is no fixed-term contract, and you have the
                    flexibility to cancel your subscription at any time.
                  </li>
                  <li className="lead mb-4">
                    Development Hours: The number of development hours allocated
                    to each subscription package is based on a monthly cycle.
                    Unused hours do not roll over to the next month. If you
                    require additional development hours, you have the option to
                    upgrade to a higher package or purchase additional hours
                    separately.
                  </li>
                  <li className="lead mb-4">
                    Service Delivery: Prolevant will make commercially
                    reasonable efforts to provide the agreed-upon development
                    services promptly and efficiently. However, we do not
                    guarantee specific delivery times or project completion
                    within a fixed time frame.
                  </li>
                  <li className="lead mb-4">
                    Support and Communication: Prolevant offers 24/7 support for
                    all subscription packages via Slack. You can contact us for
                    assistance or inquiries related to your subscription and
                    development services.
                  </li>
                  <li className="lead mb-4">
                    Limitation of Liability: Prolevant shall not be liable for
                    any direct or indirect damages arising from the subscription
                    and provision of development services. To the fullest extent
                    permitted by law, Prolevant disclaims any and all liability
                    for any loss, damage, or injury incurred by you or any third
                    party, including but not limited to financial losses, data
                    loss, or business interruption, regardless of the cause or
                    form of action.
                  </li>
                  <li className="lead mb-4">
                    Termination: You have the right to terminate your
                    subscription at any time by canceling through our website or
                    by contacting our support team. Upon termination, your
                    access to the development services will cease, and no refund
                    will be provided for any unused portion of the subscription
                    fee.
                  </li>
                  <li className="lead mb-4">
                    Amendments: Prolevant reserves the right to modify these
                    Subscription Terms at any time. Any changes will be
                    effective immediately upon posting on our website. Your
                    continued use of the subscription services after any
                    modifications constitutes your acceptance of the revised
                    terms.
                  </li>
                  <li className="lead mb-4">
                    Governing Law: These Subscription Terms are governed by and
                    construed in accordance with the laws of the jurisdiction
                    where Prolevant is based in the United Arab Emirates,
                    without regard to its conflict of laws principles.
                  </li>
                </ol>
                <p className="lead mb-5">
                  If you have any questions or concerns regarding these
                  Subscription Terms, please contact us at{" "}
                  <a
                    style={{ color: "#0a0a0a" }}
                    href="mailto:hello@prolevantstudio.com"
                  >
                    hello@prolevantstudio.com
                  </a>{" "}
                  before making a purchase.
                </p>
                <p className="lead mb-5">
                  By proceeding with the purchase, you acknowledge that you have
                  read, understood, and agreed to these Subscription Terms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
