import { useEffect, useState } from "react";
import "../HireStyle.css";
import HeaderLogo from "./HeaderLogo";
import Question from "./Question";
import { useHireContext } from "../HireContext";
import { IoIosArrowRoundBack } from "react-icons/io";
import DashedCard from "./DashedCard";
import { FaCheck } from "react-icons/fa6";
interface QuestionSidePropsType {}

export default function QuestionSide({}: QuestionSidePropsType) {
  const {
    questions,
    stepNumber,
    totalNumber,
    changeStepNumber,
    transitionForPrevStep,
    formData,
  } = useHireContext();
  // const [questions, setQuestions] = useState<questions[]>([
  //   {
  //     question: "What kind of developer are you looking for",
  //     questionNumber: 1,
  //     totalQuestion: totalNumber,
  //   },
  //   {
  //     question: "Which are the must have skill that you are looking for",
  //     questionNumber: 2,
  //     totalQuestion: totalNumber,
  //   },
  //   {
  //     question: `We found 5 or 8 developers that can help with your work`,
  //     questionNumber: 3,
  //     totalQuestion: totalNumber,
  //   },
  // ]);
  const [activeQuestion, setActiveQuestion] = useState<number>(1);
  useEffect(() => {
    setActiveQuestion(stepNumber);
  }, [stepNumber]);

  return (
    <div className="background-QuestionSide w-50 question-padding">
      <HeaderLogo />
      {stepNumber !== 1 && (
        <button
          className="back-btn"
          onClick={() => {
            transitionForPrevStep();
          }}
        >
          <IoIosArrowRoundBack
            style={{
              fontSize: "34px",
            }}
          />{" "}
          <div className="back-text">Back</div>
        </button>
      )}
      <div
        className="question-container transition"
        style={{
          maxHeight: "70vh",
          paddingBlockEnd: "5rem",
          overflow: "auto",
        }}
      >
        {questions.map((item, index) => {
          if (
            item.questionNumber == activeQuestion ||
            item.questionNumber == activeQuestion + 1
          ) {
            return (
              <div
                key={`${item.question} - ${index}`}
                className="active-question-show"
                id={`question-${index}`}
              >
                <Question
                  question={item.question}
                  questionNumber={item.questionNumber}
                  totalQuestion={item.totalQuestion}
                  isDesabled={item.questionNumber !== activeQuestion}
                />
              </div>
            );
          }
        })}
        {stepNumber === 3 && (
          <div>
            <div
              style={{
                color: "#b7b7b7",
                marginBlock: "1rem",
                fontSize: "1.2rem",
              }}
            >
              Specializaion <FaCheck style={{ marginInline: "0.5rem" }} />
            </div>
            <DashedCard
              withClose={false}
              title={formData?.specializaion || ""}
              onClose={() => {}}
            />
            <div
              style={{
                color: "#b7b7b7",
                marginBlock: "1rem",
                fontSize: "1.2rem",
              }}
            >
              Tech stack <FaCheck style={{ marginInline: "0.5rem" }} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "0.3rem",
              }}
            >
              {formData?.skills.map((e) => (
                <div key={e.label}>
                  <DashedCard
                    withClose={false}
                    title={e.label}
                    onClose={() => {}}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
