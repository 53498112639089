import { useHireContext } from "../../HireContext";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { IoIosArrowRoundBack } from "react-icons/io";
export default function HireMobile() {
  const { stepNumber, transitionForPrevStep } = useHireContext();
  return (
    <>
      {stepNumber === 1 && <Step1 />}
      {stepNumber === 2 && <Step2 />}
      {stepNumber === 3 && <Step3 />}
    </>
  );
}
