export default function PricingIntroSection() {
  return (
    <section id="slider" className="section mt-0 pt-0">
      <div className="container" style={{ zIndex: 2 }}>
        <div className="row text-center">
          <div className="col-md-12">
            <div className="heading-block mb-0 border-bottom-0 px-4 mx-auto mt-6 text-dark">
              <h1
                className="mb-5 mx-auto"
                style={{ maxWidth: "1000px", letterSpacing: "-2px !important" }}
              >
                <span className="nocolor">
                  <span
                    className={""}
                    style={{
                      textTransform: "none",
                      fontWeight: 400,
                      letterSpacing: 0,
                      color: "#0A0A0A",
                    }}
                  >
                    Own your full-time dev team, at a fraction of the cost
                  </span>
                </span>
              </h1>
              <p className="lead mb-5" style={{ color: "#000000" }}>
                Our affordable solution enable startups to work with top
                developers for just $14 per hour without sacrificing quality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
