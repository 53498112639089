import "./HireStyle.css";
import AnswerSide from "./components/AnswerSide";
import QuestionSide from "./components/QuestionSide";
import { HireContextProvider } from "./HireContext";
import HireMobile from "./components/mobile";

export default function HireTalent() {
  return (
    <HireContextProvider>
      <div>
        <div className="d-flex items-center vw-100 vh-100 hidden hire-laptop">
          <QuestionSide />
          <AnswerSide />
        </div>
        <div className="hire-mobile">
          <HireMobile />
        </div>
      </div>
    </HireContextProvider>
  );
}
