import WorkCompanySection from "./components/WorkCompanySection";
import WorkContentSection from "./components/WorkContentSection";
import WorkIntroSection from "./components/WorkIntroSection";

export default function Work() {
  return (
    <div>
      <WorkIntroSection />
      <WorkCompanySection />
      <WorkContentSection />
    </div>
  );
}
