import { useEffect } from "react";
import { IoIosClose } from "react-icons/io";

interface DashedCardPropsType {
  title: string;
  onClose: () => void;
  withClose?: boolean;
}
export default function DashedCard({
  title,
  onClose,
  withClose = true,
}: DashedCardPropsType) {
  useEffect(() => {
    let temp = document.getElementById(`id-${title}`);
    console.log(temp);
    setTimeout(() => {
      temp?.classList.remove("hide-element");
      temp?.classList.add("active-element");
    }, 200);
  }, []);
  return (
    <div
      id={`id-${title}`}
      className="dashed-card hide-element"
      style={{ transition: "all 0.2s" }}
    >
      {title}
      {withClose && (
        <div
          className="close-skill"
          style={{
            position: "absolute",
            insetBlockStart: "-5px",
            insetInlineEnd: "0px",
          }}
          onClick={() => {
            let temp = document.getElementById(`id-${title}`);
            temp?.classList.add("hide-element");
            setTimeout(() => {
              onClose();
            }, 200);
          }}
        >
          <IoIosClose />
        </div>
      )}
    </div>
  );
}
