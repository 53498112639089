import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./css/animate.css";
import "./css/bootstrap.css";
import "./css/custom.css";
import "./css/dark.css";
import "./css/font-icons.css";
import "./css/intro-fonts.css";
import "./css/magnific-popup.css";
import AppRouter from "./router.tsx";
import "./style.css";
import emailjs from "@emailjs/browser";
emailjs.init({
  publicKey: "DQmpyeA-UUhI3batX",
  // Do not allow headless browsers
  blockHeadless: true,
  // blockList: {
  // Block the suspended emails
  // list: ['foo@emailjs.com', 'bar@emailjs.com'],
  // The variable contains the email address
  // watchVariable: 'userEmail',
  // },
  limitRate: {
    // Set the limit rate for the application
    id: "app",
    // Allow 1 request per 10s
    throttle: 2000,
  },
});
ReactDOM.createRoot(document.getElementById("root")!).render(<AppRouter />);
